import React from 'react';
import useTestexchange from './useTestexchange';
import { FaArrowUpWideShort } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import {  AppDispatch, RootState } from "../../redux/store";


const TestExchange = () => {
  const signal= localStorage.getItem("signal") ;
const orderbooksocket= localStorage.getItem("orderbooksocket") ;
  const { allBtc, allBybit ,btcByBitStatus,signalperiodStatus, orderbookperiodStatus,eth_20_binance,  EthPriceBinanceStatus , ethPriceBinance, btcPriceBinanceStatus, btc_100_Bitstamp ,Eth_100_Bitstamp, btc_400_Bitstamp, Eth_400_Bitstamp ,     btc_100_okx , Eth_100_okx , btc_400_okx ,Eth_400_okx , btc_100_bybit , eth_100_bybit , btc_400_bybit ,eth_400_bybit , btcPriceBinance} = useTestexchange();
  // console.log("btcPriceBinance", btcPriceBinance);
  // console.log("Status  periodStatus=> ",periodStatus)
  const signalStatus = useSelector((state: RootState) => state.user);
  console.log("****",signalStatus.signalStatus)

  return (
    <div className="w-full min-h-screen  text-gray-200">
      {/* <div className='flex gap-10 justify-end flex-col'> */}
      <h1>BTC   <span className='text-yellow-500'> <span className={`text-yellow-600`}>{btcPriceBinance && btcPriceBinance}</span></span></h1>
      <h1>ETH   <span className='text-yellow-500'> <span className={`text-yellow-600`}>{ethPriceBinance && ethPriceBinance}</span></span></h1>
      {/* </div> */}
      <div className='flex w-full bg-white bg-opacity-15 p-2 rounded-lg justify-center gap-5 my-3'>
        {/* <div className='bg-gray-700 w-4/12 rounded-lg'>
          <h2 className='text-center py-2'>Bit Coin</h2>
          <hr />
          <div className='flex justify-between px-5 my-3 min-w-full '>
            <span>{allBtc.length > 0 ? allBtc[0].a : ''}</span>
            <span> sell</span>
          </div>
          <div className='flex justify-between px-5 my-3 min-w-full '>
            <span>{allBtc.length > 0 ? allBtc[0].b : ''}</span>
            <span> buy</span>
          </div>
          <div className='flex justify-between px-5 my-3 min-w-full '>
            <span>{allBtc.length > 0 ? allBtc[0].q : ''}</span>
            <span> qty</span>
          </div>
          <div className='flex justify-between px-5 my-3 min-w-full '>
            <span>{allBtc.length > 0 ? allBtc[0].p : ''}</span>
            <span> pish </span>
          </div>
        </div> */}
        <div className='flex justify-center items-end w-3/12 flex-col gap-4'>
        <div > Signal  Status :  <span className={` ${signal=="short"?"text-red-700":"text-green-600"}`}>{signal}</span></div>
        <div > Orderbook  Status : <span className={`${orderbooksocket=="short"?"text-red-700":"text-green-600"}`}>{orderbooksocket}</span></div>

        <div className=' bg-gray-700 flex w-full justify-center items-center border-cyan-600 border-2 rounded-md px-2 py-4 flex flex-col' >
        <h2 className='text-center py-2 text-white'>Binance <span className='text-cyan-600'>ETHUSDT</span>  20$ 
        
        </h2>
        <p>{eth_20_binance&&eth_20_binance.p}</p>
        </div>
        </div>
        <div className={`bg-gray-700 bg-opacity-20 w-4/12 rounded-lg  ${allBybit && allBybit.j == 1? "bg-green-600 border-green-700 border-1" : "bg-red-600 border-red-700 border-1"}`}>
          <h2 className='text-center py-2 text-yellow-500'>Biy bit 
</h2>
{/* <FaArrowUpWideShort className={`${allBybit && allBybit.j === 1 && "text-green-600"}`} /> */}

          <hr />
          <div className={`flex justify-between px-5 my-3 min-w-full ${allBybit && allBybit.j == 1 ? "ls" : "dfjf"}`}>
            <span className={`${allBybit && allBybit.j ==1 ? "text-green-600" : "text-red-600"}`}>{allBybit ? allBybit.a : ''}</span>
            <span> sell</span>
          </div>
          <div className='flex justify-between px-5 my-3 min-w-full '>
            <span className={`${allBybit && allBybit.j ==1  ? "text-green-600" : "text-red-600"}`}>{allBybit ? allBybit.b : ''}</span>
            <span> buy</span>
          </div>
          <div className='flex justify-between px-5 my-3 min-w-full '>
            <span>{allBybit ? allBybit.q : ''}</span>
            <span> qty</span>
          </div>
          <div className='flex justify-between px-5 my-3 min-w-full '>
            <span>{allBybit ? allBybit.p : ''}</span>
            <span> pish </span>
          </div>
        </div>
        <div className={`bg-gray-700 bg-opacity-20 w-4/12 rounded-lg  ${allBtc && allBtc .j == 1? "bg-green-600 border-green-700 border-1" : "bg-red-600 border-red-700 border-1"}`}>
          <h2 className='text-center py-2 text-yellow-500'>Bitcoin 
</h2>
          <hr />
          <div className={`flex justify-between px-5 my-3 min-w-full ${allBtc && allBtc.j === 1 ? "ls" : "dfjf"}`}>
            <span className={`${allBtc && allBtc.j == 1 ? "text-green-600" : "text-red-600"}`}>{allBtc ? allBtc.a : ''}</span>
            <span> sell</span>
          </div>
          <div className='flex justify-between px-5 my-3 min-w-full '>
            <span className={`${allBtc && allBtc.j == 1? "text-green-600" : "text-red-600"}`}>{allBtc ? allBtc.b : ''}</span>
            <span> buy</span>
          </div>
          <div className='flex justify-between px-5 my-3 min-w-full '>
            <span>{allBtc ? allBtc.q : ''}</span>
            <span> qty</span>
          </div>
          <div className='flex justify-between px-5 my-3 min-w-full '>
            <span>{allBtc ? allBtc.p : ''}</span>
            <span> pish </span>
          </div>
        </div>
      </div>
      <div className='flex w-full bg-white bg-opacity-15 p-10 rounded-lg justify-center gap-5   my-10'>
      <div className='bg-gray-700 w-3/12 rounded-lg '>
      <h2 className='text-center py-2 text-white'><span className=''>Bitstamp</span>   <span className='text-yellow-600'>BTCUSDT</span>  100$ 
</h2>
          <hr />
       
          <div className='flex justify-between px-5 my-3 min-w-full '>
            <span>{btc_100_Bitstamp ? btc_100_Bitstamp.p : ''}</span>
            <span> pish </span>
          </div>
        </div>
        <div className='bg-gray-700 w-3/12 rounded-lg'>
      <h2 className='text-center py-2 text-white'>Bitstamp <span className='text-yellow-600'>BTCUSDT</span>  400$ 
</h2>
          <hr />
       
          <div className='flex justify-between px-5 my-3 min-w-full  '>
            <span>{btc_400_Bitstamp ? btc_400_Bitstamp.p : ''}</span>
            <span> pish </span>
          </div>
        </div>
        <div className='bg-gray-700 w-3/12 rounded-lg'>
          <h2 className='text-center py-2 text-white'>Bitstamp <span className='text-cyan-600'>ETHUSDT</span>  100$ 
</h2>
          <hr />
       
          <div className='flex justify-between px-5 my-3 min-w-full '>
            <span>{Eth_100_Bitstamp ? Eth_100_Bitstamp.p : ''}</span>
            <span> pish </span>
          </div>
        </div>
        <div className='bg-gray-700 w-3/12 rounded-lg'>
          <h2 className='text-center py-2 text-white'>Bitstamp <span className='text-cyan-600'>ETHUSDT</span>  400$ 
</h2>
          <hr />
       
          <div className='flex justify-between px-5 my-3 min-w-full '>
            <span>{Eth_400_Bitstamp ? Eth_400_Bitstamp.p : ''}</span>
            <span> pish </span>
          </div>
        </div>
        
        
</div>


















{/*     btc_100_okx , Eth_100_okx , btc_400_okx ,Eth_400_okx */}

<div className='flex w-full bg-white bg-opacity-15 p-10 rounded-lg justify-center gap-5 my-10'>
      <div className='bg-gray-700 w-3/12 rounded-lg'>
      <h2 className='text-center py-2 text-white'>OKX <span className='text-yellow-600'>BTCUSDT</span>  100$ 
</h2>
          <hr />
       
          <div className='flex justify-between px-5 my-3 min-w-full '>
            <span>{btc_100_okx ? btc_100_okx.p : ''}</span>
            <span> pish </span>
          </div>
        </div>
        <div className='bg-gray-700 w-3/12 rounded-lg'>
      <h2 className='text-center py-2 text-white'>OKX <span className='text-yellow-600'>BTCUSDT</span>  400$ 
</h2>
          <hr />
       
          <div className='flex justify-between px-5 my-3 min-w-full '>
            <span>{btc_400_okx ? btc_400_okx.p : ''}</span>
            <span> pish </span>
          </div>
        </div>
        <div className='bg-gray-700 w-3/12 rounded-lg'>
          <h2 className='text-center py-2 text-white'>OKX <span className='text-cyan-600'>ETHUSDT</span>  100$ 
</h2>
          <hr />
       
          <div className='flex justify-between px-5 my-3 min-w-full '>
            <span>{Eth_100_okx ? Eth_100_okx.p : ''}</span>
            <span> pish </span>
          </div>
        </div>
        <div className='bg-gray-700 w-3/12 rounded-lg'>
          <h2 className='text-center py-2 text-white'>OKX <span className='text-cyan-600'>ETHUSDT</span>  400$ 
</h2>
          <hr />
       
          <div className='flex justify-between px-5 my-3 min-w-full '>
            <span>{Eth_400_okx ? Eth_400_okx.p : ''}</span>
            <span> pish </span>
          </div>
        </div>
        
        
</div>









<div className='flex w-full bg-white bg-opacity-15 p-10 rounded-lg justify-center gap-5 my-10'>
      <div className='bg-gray-700 w-3/12 rounded-lg'>
      <h2 className='text-center py-2 text-white'>Bybit <span className='text-yellow-600'>BTCUSDT</span>  100$ 
</h2>
          <hr />
       
          <div className='flex justify-between px-5 my-3 min-w-full '>
            <span>{btc_100_bybit ? btc_100_bybit.p : ''}</span>
            <span> pish </span>
          </div>
        </div>
        <div className='bg-gray-700 w-3/12 rounded-lg'>
      <h2 className='text-center py-2 text-white'>Bybit <span className='text-yellow-600'>BTCUSDT</span>  400$ 
</h2>
          <hr />
       
          <div className='flex justify-between px-5 my-3 min-w-full '>
            <span>{btc_400_bybit ? btc_400_bybit.p : ''}</span>
            <span> pish </span>
          </div>
        </div>
        <div className='bg-gray-700 w-3/12 rounded-lg'>
          <h2 className='text-center py-2 text-white'>Bybit <span className='text-cyan-600'>ETHUSDT</span>  100$ 
</h2>
          <hr />
       
          <div className='flex justify-between px-5 my-3 min-w-full '>
            <span>{eth_100_bybit ? eth_100_bybit.p : ''}</span>
            <span> pish </span>
          </div>
        </div>
        <div className='bg-gray-700 w-3/12 rounded-lg'>
          <h2 className='text-center py-2 text-white'>Bybit <span className='text-cyan-600'>ETHUSDT</span>  400$ 
</h2>
          <hr />
       
          <div className='flex justify-between px-5 my-3 min-w-full '>
            <span>{eth_400_bybit ? eth_400_bybit.p : ''}</span>
            <span> pish </span>
          </div>
        </div>
        
        
</div>












    </div>
  );
};

export default TestExchange;
