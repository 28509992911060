import React, { useEffect, useState } from 'react';
import { centralNodesocketClient as socketClient } from "../../Socket/centralNodeSocket";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { setSignalStatus } from '../../redux/slices/userSlice';

type OrderbookData = {
  a: number;
  b: number;
  d: number;
  p: number;
  q: number;
  j?:number
};

function useTestexchange() {
  const dispatch = useDispatch<AppDispatch>();

  const [allBtc, setAllBtc] = useState<OrderbookData>();
  const [allBybit, setAllBybit] = useState<OrderbookData>();

  const [btc_100_Bitstamp, setBtc_100_Bitstamp] = useState<OrderbookData>();
  const [Eth_100_Bitstamp, setEth_100_Bitstamp] = useState<OrderbookData>();

  const [btc_400_Bitstamp, setBtc_400_Bitstamp] = useState<OrderbookData>();
  const [Eth_400_Bitstamp, setEth_400_Bitstamp] = useState<OrderbookData>();

  const [btc_100_okx, setBtc_100_okx] = useState<OrderbookData>();
  const [Eth_100_okx, setEth_100_okx] = useState<OrderbookData>();
  const [btc_400_okx, setBtc_400_okx] = useState<OrderbookData>();
  const [Eth_400_okx, setEth_400_okx] = useState<OrderbookData>();

  const [btc_100_bybit, setBtc_100_bybit] = useState<OrderbookData>();
  const [eth_100_bybit, setEth_100_bybit] = useState<OrderbookData>();
  const [btc_400_bybit, setBtc_400_bybit] = useState<OrderbookData>();
  const [eth_400_bybit, setEth_400_bybit] = useState<OrderbookData>()

  const [btcPriceBinance, setBtcPriceBinance] = useState<number>(0)
  const [ethPriceBinance, setEthPriceBinance] = useState<number>(0)
  const [bybitPrice, setBybitPrice] = useState<number>(0)
  const [btcByBitStatus , setbtcByBitStatus] = useState<"DEC"|"ASC">("ASC")

  const [btcPriceBinanceStatus, btcEthPriceBinanceStatus] = useState<"DEC"|"ASC">("ASC")
  const [EthPriceBinanceStatus, setEthPriceBinanceStatus] = useState<"DEC"|"ASC">("ASC")
  const [signalperiodStatus, setSignalPeriodStatus] = useState<"short"|"long"|"">("")
  const [orderbookperiodStatus, setorderbookPeriodStatus] = useState<"short"|"long"|""|null>("")

  const [eth_20_binance, setEth_20_binance] = useState<OrderbookData>();

  useEffect(() => {
    socketClient.emit("join-room", "binance-BTCUSDT-orderbook-all");
    socketClient.emit("join-room", "bybit-BTCUSDT-orderbook-all");
    socketClient.emit("join-room", "binance-ETHUSDT-orderbook-20$");


    socketClient.emit("join-room", "bitstamp-BTCUSDT-orderbook-100$");
    socketClient.emit("join-room", "bitstamp-ETHUSDT-orderbook-100$");
    socketClient.emit("join-room", "bitstamp-BTCUSDT-orderbook-400$");
    socketClient.emit("join-room", "bitstamp-ETHUSDT-orderbook-400$");

    socketClient.emit("join-room", "okx-BTCUSDT-orderbook-100$");
    socketClient.emit("join-room", "okx-ETHUSDT-orderbook-100$");
    socketClient.emit("join-room", "okx-BTCUSDT-orderbook-400$");
    socketClient.emit("join-room", "okx-ETHUSDT-orderbook-400$");
    


    socketClient.emit("join-room", "bybit-BTCUSDT-orderbook-100$");
    socketClient.emit("join-room", "bybit-ETHUSDT-orderbook-100$");
    socketClient.emit("join-room", "bybit-BTCUSDT-orderbook-400$");
    socketClient.emit("join-room", "bybit-ETHUSDT-orderbook-400$");

    socketClient.emit("join-room", "binance-ETHUSDT-price");
    socketClient.emit("join-room", "binance-BTCUSDT-price");
    socketClient.emit("join-room", "bybit-BTCUSDT-price");
    socketClient.emit("join-room", "signal-period");
    socketClient.emit("join-room", "orderbook-period");

    
    socketClient.on("binance-ETHUSDT-orderbook-20$-res", (data: OrderbookData) => {            
      // console.log(" eth 20$ ",data)
      
      
     
      setEth_20_binance(data)
          });
       

    socketClient.on("signal-period-res", (data: "long"| "short") => {            
      console.log("Signal Status =========== > ",data)
      // dispatch(data);

      localStorage.setItem("signal", data)

      setSignalPeriodStatus(data)
          });
          
    socketClient.on("orderbook-period-res", (data: "long"| "short") => {            
      console.log("Orderbook Status =========== > ",data)
      setorderbookPeriodStatus(data)
      localStorage.setItem("orderbooksocket", data)

          });

    socketClient.on("bybit-BTCUSDT-price-res", (data: number) => {            
      // console.log("BTC BYBIT ",data)
      
      setbtcByBitStatus(bybitPrice<data?"ASC":"DEC")
      
     
        setBybitPrice(data)
          });

    socketClient.on("binance-BTCUSDT-price-res", (data: number) => {            
      // console.log("btc BTCUSDT ",data)
      
        btcEthPriceBinanceStatus(btcPriceBinance<data?"ASC":"DEC")
      
     
      setBtcPriceBinance(data)
          });
  socketClient.on("binance-ETHUSDT-price-res", (data: number) => {            
    setEthPriceBinanceStatus(ethPriceBinance<data?"ASC":"DEC")
    setEthPriceBinance(data)
          });


  // bybit 100 btc
  socketClient.on("bybit-BTCUSDT-orderbook-100$-res", (data: OrderbookData) => {            
    setBtc_100_bybit(data);
    });
     // bybit 400 btc
  socketClient.on("bybit-BTCUSDT-orderbook-400$-res", (data: OrderbookData) => {            
    setBtc_400_bybit(data);
    });
// bybit 100 btc
  socketClient.on("bybit-ETHUSDT-orderbook-100$-res", (data: OrderbookData) => { 
    setEth_100_bybit(data);
    });
 // bybit 400 btc
 socketClient.on("bybit-ETHUSDT-orderbook-400$-res", (data: OrderbookData) => {            
  setEth_400_bybit(data);
  });












    // okx 100 btc
    socketClient.on("okx-BTCUSDT-orderbook-100$-res", (data: OrderbookData) => {            
      setBtc_100_okx(data);
      });
       // okx 400 btc
    socketClient.on("okx-BTCUSDT-orderbook-400$-res", (data: OrderbookData) => {            
      setBtc_400_okx(data);
      });
 // okx 100 btc
    socketClient.on("okx-ETHUSDT-orderbook-100$-res", (data: OrderbookData) => { 
  setEth_100_okx(data);
      });
   // okx 400 btc
   socketClient.on("okx-ETHUSDT-orderbook-400$-res", (data: OrderbookData) => {            
    setEth_400_okx(data);
    });





    socketClient.on("bitstamp-BTCUSDT-orderbook-400$-res", (data: OrderbookData) => {            
      setBtc_400_Bitstamp(data);
  
      });
      socketClient.on("bitstamp-ETHUSDT-orderbook-400$-res", (data: OrderbookData) => {            
        setEth_400_Bitstamp(data);
    
        });



    socketClient.on("bitstamp-BTCUSDT-orderbook-100$-res", (data: OrderbookData) => {            
      setBtc_100_Bitstamp(data);
  
      });
    socketClient.on("bitstamp-ETHUSDT-orderbook-100$-res", (data: OrderbookData) => {            
    setEth_100_Bitstamp(data);
    });
    socketClient.on("binance-BTCUSDT-orderbook-all-res", (data: OrderbookData) => {            
      if (data.q > 0) {
      
        data.j = 1;
      } else {
        data.j = 0;
      }
      setAllBtc(data); 

    });
    socketClient.on("bybit-BTCUSDT-orderbook-all-res", (data: OrderbookData) => {
      if (data.q > 0) {
      
        data.j = 1;
      } else {
        data.j = 0;
      }
      setAllBybit(data);
    });

    return () => {
      // Cleanup on component unmount
      // socketClient.off("binance-BTCUSDT-orderbook-50$-res");
    };
  }, []); // Empty dependency array means this effect runs once on component mount

  return { allBtc ,allBybit,btc_100_Bitstamp , Eth_100_Bitstamp , btc_400_Bitstamp ,Eth_400_Bitstamp, signalperiodStatus ,orderbookperiodStatus ,eth_20_binance ,
    btc_100_okx , Eth_100_okx , btc_400_okx ,Eth_400_okx ,btc_100_bybit , eth_100_bybit , btc_400_bybit ,eth_400_bybit , btcPriceBinance , btcPriceBinanceStatus ,  EthPriceBinanceStatus , ethPriceBinance , btcByBitStatus
  };
}
   

export default useTestexchange;
