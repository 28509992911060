import React, { useEffect } from "react";
import "./App.css";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "./redux/store";
import Layout from "./components/Layout";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import { BrowserRouter } from "react-router-dom";
import Login from "./components/Login";
import { setToken, setUserInfo } from "./redux/slices/userSlice";
import ForgetPassword from "./components/Login/forgetPassword";
function App() {
  const user = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    const token = localStorage.getItem("token") || "";
    if (token) {
      dispatch(setToken(token));
    }
  }, []);

  return (
    <div className="App bg-sec">
      <BrowserRouter>{user.token ? <Layout /> : <Login />}</BrowserRouter>
      <ToastContainer
        position="top-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}

export default App;
