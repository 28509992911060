import io from "socket.io-client";
import { constants } from "../constants/constants";

console.log("constants: ",constants);

const centralNodesocketClient = io(constants.centralUrl, { transports: ["websocket"],  reconnection: true,
reconnectionAttempts: Infinity,
reconnectionDelay: 1000,
reconnectionDelayMax: 5000, });

centralNodesocketClient.on("connect", () => {
  console.log("join");
});

export { centralNodesocketClient };