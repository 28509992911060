import React from 'react'

function 
ForgetPassword() {
  return (
    <>
    <>
      <div className="bg-gray-900">
        <div className="flex justify-center h-screen">
          <div className="flex items-center w-full   h-screen max-w-md px-6 mx-auto md:w-1/2 xl:w-2/3">

            <div className="w-full  h-full  ">
              <div className=" flex  justify-center p-4 w-full pb-3 h-2/6 items-center">
                <img src="./images/logorobo-2.png" alt="logorobo" className="w-32 h-44" />
              </div>
              <div className="h-4/6">
                <div className="text-center pt-10 flex flex-col gap-y-4">
                  
                  <p className="mt-3 text-gray-500 dark:text-gray-300">
                    وارد حساب کاربری خود شوید
                  </p>
                </div>
                <div className="mt-4">

                </div>
              </div>
            </div>
          </div>
          <div
            className="hidden bg-cover md:block md:w-1/2 xl:w-2/3"
            style={{
              backgroundImage:
                "url(./images/ofl-login.png)"
            }}
          >
            {/* <div className="flex items-center h-full px-20 w-full justify-center bg-gray-900 relative bg-opacity-40">
              <div className="  p-4  absolute top-5 right-5">
                <img src="./images/logorobo-2.png" alt="logorobo" className="w-32 h-32" />
              </div>
            </div> */}
          </div>

        </div>
      </div>
    </>
  </>  )
}

export default ForgetPassword